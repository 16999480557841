import React from 'react';

export function useStickyState<valueType>(
  defaultValue: valueType,
  key: string,
): [valueType, (value: valueType) => void] {
  const [value, setValue] = React.useState<valueType>(() => {
    try {
      const stickyValue = window.localStorage.getItem(key);
      return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
    } catch (e) {
      return defaultValue;
    }
  });

  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}
