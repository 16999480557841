import { Commit, Store } from '@app:types';
import { useToast, UseToastOptions } from '@chakra-ui/react';
import React from 'react';
import store, { StoreState } from './store';

export class UseToastIntercept {
  constructor(public options: UseToastOptions) {}
}

type StoreProviderState = {
  state: StoreState;
  dispatch: Commit<StoreState>;
};
const StoreContext = React.createContext<StoreProviderState>(undefined!);

export default function StoreProvider({
  onSave,
  savedState,
  children,
}: {
  onSave?: (state: StoreState) => void;
  savedState?: StoreState;
  children: React.ReactNode;
}) {
  const [state, setState] = React.useState(store.getState());
  const toast = useToast({ status: 'error', duration: 3000, isClosable: true });

  const dispatch = React.useCallback(
    (name: keyof StoreState, action: string, payload?: any, successCallback?: () => void, failCallback?: () => void) => {
      console.log(name, action, payload);
      store
        .runAction(name, action, payload)
        .then(successCallback)
        .catch((e) => {
          if (e instanceof UseToastIntercept) toast(e.options);
          else if (e instanceof Error) toast({ title: e.message });

          if (typeof failCallback === 'function') failCallback();
        });
    },
    [], //WARN: keep dependency array empty - otherwise application crashes.
  );

  React.useEffect(() => {
    if (typeof onSave === 'function') onSave(state);
  }, [state, onSave]);

  React.useEffect(() => {
    store.addListener(Store.EVENTS.ON_UPDATE, setState);
    return () => {
      store.removeListener(Store.EVENTS.ON_UPDATE, setState);
    };
  }, []);

  if (process.env.REACT_APP_LOG_STORE_STATE === '1') console.log(state);

  return <StoreContext.Provider value={{ state, dispatch }}>{children}</StoreContext.Provider>;
}

export function useStore(): [StoreState, Commit<StoreState>] {
  const context = React.useContext(StoreContext);
  if (context === undefined) {
    throw new Error('useStore must be used within a Store Provider.');
  }

  return [context.state, context.dispatch];
}
