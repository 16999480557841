import { SimplePageTemplate } from '@components/layout';
import { Text } from '@chakra-ui/react';

import { RiLineChartLine } from 'react-icons/ri';

const page = () => {
  return (
    <SimplePageTemplate
      {...{
        title: 'Monitoring',
        icon: RiLineChartLine,
      }}
      content={<Text>Hello</Text>}
    />
  );
};

export default page;
