import React from 'react';

import api from '@ellure/api-client-admin';
import { MaterialSupplier } from '@ellure/types';

import { Select } from '@chakra-ui/react';

export function SupplierSelectInput(props: { value: string; onChange: (val: any) => void }) {
  const [options, setOptions] = React.useState<MaterialSupplier[]>([]);

  React.useEffect(() => {
    api.resources.suppliers.get({ query: { page: 0, limit: 60 } }).then((response) => {
      setOptions(response.data.data);
      props.onChange(options[0].supplierId);
    });
  }, []);

  const handleChange = (event: any) => {
    props.onChange(event.target.value);
  };

  return (
    <Select onChange={handleChange} value={props.value}>
      <option value=''>--SELECT A SUPPLIER--</option>
      {options.map((option) => (
        <option key={option.supplierId} value={option.supplierId}>
          {option.supplierName}
        </option>
      ))}
    </Select>
  );
}
