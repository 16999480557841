import React from 'react';

import { AuthProvider, useAuth, WebSocketProvider } from '@app:services';
import { useNavigate } from 'react-router-dom';

import {
  ArchivedOrdersPage,
  MiscRoutes,
  MonitoringRoutes,
  PendingOrdersPage,
  ProductionOrdersPage,
  ResourceRoutes,
  SettingsRoutes,
} from './views';

import { Frame } from '@components/layout';
import { Route, Routes } from 'react-router-dom';

function App() {
  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';

  return (
    <AuthProvider>
      <WebSocketProvider
        uri={`${protocol}//${process.env.REACT_APP_API_HOST?.replace('http://', '').replace('https://', '')}/api/dispatch/websockets`}
      >
        <AppRouting />
      </WebSocketProvider>
    </AuthProvider>
  );
}

function AppRouting() {
  return (
    <Frame>
      <Routes>
        <Route path='/' element={<PendingOrdersPage />} />
        <Route path='/orders' element={<PendingOrdersPage />} />
        <Route path='/devices' element={<ProductionOrdersPage />} />
        <Route path='/archive' element={<ArchivedOrdersPage />} />
        <Route path='/monitoring' element={<MonitoringRoutes.OverviewPage />} />
        <Route path='/resources' element={<ResourceRoutes.OverviewPage />} />
        <Route path='/resources/:materialId' element={<ResourceRoutes.MaterialDetail />} />
        <Route path='/settings' element={<SettingsRoutes.Settings />} />
        <Route path='/signout' element={<Signout />} />

        <Route path='/auth/shopify' element={<MiscRoutes.Shopify />} />
      </Routes>
    </Frame>
  );
}

const Signout = () => {
  const { signout } = useAuth();
  const navigate = useNavigate();
  React.useEffect(() => {
    signout()
      .then(() => {
        navigate('/');
      })
      .catch(() => {
        navigate('/');
      });
  }, []);

  return null;
};

export default App;
