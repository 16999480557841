import React from 'react';

import api from '@ellure/api-client-admin';
import { InnerProductAttachmentCategories, MaterialBatch } from '@ellure/types';

import { Button, ButtonGroup, Flex, Progress, Text, useDisclosure } from '@chakra-ui/react';
import { FileManager, Modal } from '@components';
import { HeaderActionButton, ListTableColumn, ListView } from '@components/data-display/list-view';

import { RiFileEditLine } from 'react-icons/ri';

export function MaterialBatchList(props: {
  limit: number;
  headerActions: HeaderActionButton[];
  simple: boolean;

  materialId: string;

  isInput: boolean;
  selected?: string[];
  onSelect?: (value: string[]) => void;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [batch, setBatch] = React.useState<MaterialBatch | undefined>(undefined);

  const columns: ListTableColumn<MaterialBatch>[] = props.simple
    ? [
        {
          key: 'batchNumber',
          title: 'Batch number',
        },
        {
          key: 'bookedAt',
          title: 'Booking Date',
        },
      ]
    : [
        {
          key: '@action',
          title: 'actions',
          render: (data, item) => (
            <ButtonGroup>
              <Button
                size='sm'
                variant='outline'
                colorScheme='blue'
                onClick={() => {
                  setBatch(item);
                  onOpen();
                }}
              >
                <RiFileEditLine />
              </Button>
            </ButtonGroup>
          ),
          props: {
            width: '100px',
          },
        },
        {
          key: 'batchNumber',
          title: 'Batch number',
        },
        {
          key: 'stock',
          title: 'Available stock',
          render: (stock) => (
            <Flex alignItems={'center'} gap={2}>
              <Progress flex={2} value={(stock.available.amount / stock.initial.amount) * 100} />
              <Text flex={1}>
                {stock.available.amount} / {stock.initial.amount} {stock.initial.unit}
              </Text>
            </Flex>
          ),
        },
        {
          key: 'supplierId',
          title: 'Supplier',
        },
        {
          key: 'expiresAt',
          title: 'Expiration Date',
          render: (val: string) => val.split('T')[0],
        },
      ];

  return (
    <>
      <ListView<MaterialBatch>
        limit={props.limit}
        emptyMessage={'No batches recorded'}
        dataApi={async (currentPage: number) => {
          const response = await api.resources.batches.get({
            query: { page: currentPage, limit: props.limit },
            params: { materialId: props.materialId },
          });
          console.log(response);
          return response.data;
        }}
        dataId='batchId'
        title='Feedstock Batch list'
        headerActions={props.headerActions}
        headerVisible={!props.simple}
        columns={columns}
        controlled={
          props.isInput
            ? {
                selectable: true,
                selected: props.selected!,
                onSelect: props.onSelect!,
              }
            : undefined
        }
      />
      {batch && (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          title='Manage attachments'
          modalContentStyle={{ maxW: '800px', w: '90%' }}
        >
          <FileManager<typeof InnerProductAttachmentCategories>
            categories={['SDS', 'COA', 'OTHER']}
            files={batch.attachments as any}
            onUpload={async (body) => {
              await api.resources.batches.uploadAttachment({
                body,
                params: { batchId: batch.batchId, materialId: props.materialId },
              });
            }}
            onDelete={async (filename) => {
              await api.resources.batches.deleteAttachment({
                params: { batchId: batch.batchId, materialId: props.materialId, filename },
              });
            }}
          />
        </Modal>
      )}
    </>
  );
}

MaterialBatchList.defaultProps = {
  simple: false,
  headerActions: [],
  isInput: false,
};

/*
{material && <Modal isOpen={isOpen} onClose={onClose} title={`Create a new ${material.materialDisplayName} batch`}>
      <CreateBatch materialId={material.materialId} />
    </Modal>}
*/
