import { EventEmitter } from 'events';

export type Commit<S> = (name: keyof S, action: string, payload?: any, successCallback?: () => void, failCallback?: () => void) => void;

export type ReducerContext<S> = {
  [func: string]: (state: S, payload?: any) => any;
};

export type ActionContext<S, M> = Record<
  string,
  (injectee: { mutate: (type: M, payload?: any) => void; rootState: S }, payload?: any) => any
>;

export type Slice<StoreState, State, Mutations> = {
  name: string;
  state: State;
  reducers: ReducerContext<State>;
  actions: ActionContext<StoreState, Mutations>;
};

export class Store<State> extends EventEmitter {
  static EVENTS = {
    ON_UPDATE: 'on-update',
  } as const;

  constructor(private slices: Record<keyof State, Slice<State, any, any>>) {
    super();
  }

  private mutate(slice: Slice<State, any, any>, type: string, payload?: any) {
    slice.reducers[type](slice.state, payload);
    this.emit(Store.EVENTS.ON_UPDATE, this.getState());
  }

  public getState() {
    const state: Record<string, any> = {};
    for (const slice of Object.values<Slice<State, any, any>>(this.slices)) {
      state[slice.name] = JSON.parse(JSON.stringify(slice.state));
    }
    return state as State;
  }

  public async runAction(name: keyof State, action: string, payload?: any) {
    const slice = Object.values<Slice<State, any, any>>(this.slices).find((s) => s.name === name);
    if (!slice) {
      console.warn(`Slice ${name.toString()} does not exist`);
      return;
    }

    try {
      await slice.actions[action]({ mutate: this.mutate.bind(this, slice), rootState: this.getState() }, payload);
    } catch (e) {
      console.warn(`COMMIT@${name.toString()} failed: action "${action}" failed or does not exist.`);
      console.log(e);
      throw e;
    }
  }
}
