import React from 'react';

import api from '@ellure/api-client-admin';
import { MaterialSupplier } from '@ellure/types';

import { useEffectPostMount } from '@utils/hooks';

import { UpdateSupplierForm } from '@forms';

import { Button, ButtonGroup, useDisclosure } from '@chakra-ui/react';
import { HeaderActionButton, ListTableColumn, ListView } from '@components/data-display/list-view';
import { Modal } from '@components/layout';

function ActionButtons(
  props: MaterialSupplier & {
    onChange: () => void;
  },
) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <ButtonGroup>
        <Button size='sm' variant='outline' colorScheme='blue' onClick={onOpen}>
          Edit
        </Button>
      </ButtonGroup>
      <Modal title={`Update '${props.supplierName}'`} isOpen={isOpen} onClose={onClose}>
        <UpdateSupplierForm
          refId={props.supplierId}
          defaultValues={{
            address: props.address,
            city: props.city,
            country: props.countryCode,
            email: props.email,
            name: props.supplierName,
            postcode: props.postcode,
          }}
          onSubmit={() => {
            props.onChange();
            onClose();
          }}
        />
      </Modal>
    </>
  );
}

export function MaterialSupplierList(props: {
  limit: number;
  headerActions: HeaderActionButton[];
  simple: boolean;
  isInput: boolean;
  selected?: string[];
  refresh: boolean;
  onSelect?: (value: string[]) => void;
}) {
  const [refresh, setRefresh] = React.useState(!!props.refresh);

  useEffectPostMount(() => {
    setRefresh(!refresh);
  }, [props.refresh]);

  const columns: ListTableColumn<MaterialSupplier>[] = props.simple
    ? [
        {
          key: 'supplierName',
          title: 'Supplier name',
        },
      ]
    : [
        {
          key: '@action',
          title: 'actions',
          render: (data, item) => <ActionButtons {...item} onChange={() => setRefresh(!refresh)} />,
          props: {
            width: '200px',
          },
        },
        {
          key: 'supplierName',
          title: 'Supplier name',
        },
        {
          key: 'email',
          title: 'Contact',
        },
      ];

  return (
    <ListView<MaterialSupplier>
      limit={props.limit}
      refresh={refresh}
      emptyMessage={'No feedstock defined'}
      dataApi={async (currentPage: number) => {
        const response = await api.resources.suppliers.get({ query: { page: currentPage, limit: props.limit } });
        return response.data;
      }}
      dataId='supplierId'
      title='Supplier list'
      headerActions={props.headerActions}
      headerVisible={!props.simple}
      columns={columns}
      controlled={
        props.isInput
          ? {
              selectable: true,
              selected: props.selected!,
              onSelect: props.onSelect!,
            }
          : undefined
      }
    />
  );
}

MaterialSupplierList.defaultProps = {
  simple: false,
  headerActions: [],
  isInput: false,
  refresh: false,
};
