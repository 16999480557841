import React from 'react';

import { IAttachment } from '@ellure/types';

import {
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Icon,
  Flex,
  Badge,
  ButtonGroup,
  FormLabel,
  Link,
} from '@chakra-ui/react';
import { BoxLoader } from '@components/layout';

import { RiDeleteBin2Line, RiFileDownloadLine, RiFileLine, RiFileUploadLine } from 'react-icons/ri';

export function FileManager<Categories>(props: {
  categories: (keyof Categories)[];
  files: (IAttachment & { category: keyof Categories })[];
  onUpload: (body: FormData) => Promise<void>;
  onDelete: (filename: string) => Promise<void>;
}) {
  console.log(props.files);

  const [isLoading, setLoading] = React.useState(false);
  const categoryArrays = props.categories.reduce<{ [key: string]: IAttachment[] }>((obj, key) => {
    obj[key as string] = [];
    return obj;
  }, {});

  props.files.forEach((file) => {
    if (file.category in categoryArrays) {
      categoryArrays[file.category as string].push(file);
    }
  });

  const uploadFile = async (category: string, event: any) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    formData.append('category', category);
    try {
      await props.onUpload(formData);
    } catch (e) {
      console.log(e);
    } finally {
      event.target.value = '';
      setLoading(false);
    }
  };

  const deleteFile = async (filename: string) => {
    setLoading(true);
    try {
      await props.onDelete(filename);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BoxLoader isLoading={isLoading}>
      <Table>
        <Thead>
          <Tr>
            <Th p={1} pl={3} w='60px'>
              Type
            </Th>
            <Th p={1} pl={3} w='calc(100% - 400px)'>
              Name
            </Th>
            <Th p={1} pl={3} w='150px'>
              By
            </Th>
            <Th p={1} pl={3} w='130px'>
              At
            </Th>
            <Th p={1} pl={3} w='60px' />
          </Tr>
        </Thead>
        <Tbody>
          {Object.entries(categoryArrays).map(([category, attachments]) => {
            if (attachments.length === 0)
              return (
                <Tr>
                  <Td p={1} pl={3}>
                    <Badge title='Safety Data Sheet'>{category}</Badge>
                  </Td>
                  <Td p={1} pl={3}>
                    <Flex gap={3} alignItems={'center'}>
                      <Button variant='outline' p={0}>
                        <FormLabel htmlFor={category} p={4} m={0}>
                          <RiFileUploadLine />
                        </FormLabel>
                      </Button>
                      <input id={category} type='file' hidden onChange={uploadFile.bind(null, category)} />
                    </Flex>
                  </Td>
                  <Td p={1} pl={3}></Td>
                  <Td p={1} pl={3}></Td>
                  <Td p={1} pl={3}></Td>
                </Tr>
              );

            return attachments.map((attachment, index, { length }) => {
              const borderBottom = index !== length - 1 ? '0px' : undefined;
              const url = process.env.REACT_APP_API_HOST + `/api/resources/attachments/${attachment.name}`;
              const path = attachment.name.split('/');
              return (
                <Tr key={attachment.name}>
                  <Td p={1} pl={3} borderBottom={borderBottom}>
                    <Badge title='Safety Data Sheet'>{index === 0 && category}</Badge>
                  </Td>
                  <Td p={1} pl={3} borderBottom={borderBottom}>
                    <Flex height='100%' w='100px' gap={3} alignItems={'center'}>
                      <Icon as={RiFileLine} />
                      <Link href={url} target='_blank'>
                        {path[path.length - 1]}
                      </Link>
                    </Flex>
                  </Td>
                  <Td p={1} pl={3} borderBottom={borderBottom}>
                    {attachment.uploadedBy}
                  </Td>
                  <Td p={1} pl={3} borderBottom={borderBottom}>
                    {attachment.uploadedAt.split('T')[0]}
                  </Td>
                  <Td p={1} pl={3} borderBottom={borderBottom}>
                    <ButtonGroup>
                      <Link href={url} target='_blank'>
                        <Button variant='outline'>
                          <RiFileDownloadLine />
                        </Button>
                      </Link>
                      <Button variant='outline' onClick={deleteFile.bind(null, path[path.length - 1])}>
                        <RiDeleteBin2Line />
                      </Button>
                    </ButtonGroup>
                  </Td>
                </Tr>
              );
            });
          })}
        </Tbody>
      </Table>
    </BoxLoader>
  );
}
