import '@fontsource/libre-caslon-display';
import '@fontsource/work-sans';

import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    brand: {
      900: '#1a365d',
      800: '#153e75',
      700: '#2a69ac',
    },
    'pigment-Red': {
      500: '#ff0000',
    },
    'pigment-Yellow': {
      500: '#fffF00',
    },
    'pigment-Blue': {
      500: '#0000ff',
    },
    'pigment-Black': {
      500: '#000000',
    },
    'pigment-White': {
      500: '#eeeeee',
    },
    'pigment-Base': {
      500: '#dedbc1',
    },
  },
  fonts: {
    heading: 'Libre Caslon Display, sans-serif',
    body: 'Work Sans, sans-serif',
  },
  components: {
    Heading: {
      baseStyle: {
        fontWeight: '300',
      },
    },
  },
  styles: {
    global: {
      '*': {
        boxSizing: 'border-box',
      },
      'html, body': {
        height: '100%',
        width: '100%',
        margin: 0,
        padding: 0,
        fontFamily: 'sans-serif',
      },
      '::-webkit-scrollbar': {
        w: '0.1rem',
        bgColor: 'transparent',
      },
      '::-webkit-scrollbar-thumb': {
        bgColor: '#ababab',
      },
      '*': {
        scrollbarColor: '#ababab transparent',
      },
    },
  },
});

export default theme;
