import { ActionContext, SortingModes } from '@app:types';
import api from '@ellure/api-client-admin';
import { SortingKeys } from '@ellure/api-client-admin/build/@api/order-register-endpoints';
import { OrderTemplate } from '@ellure/types';
import { StoreState } from '@store/store';
import { OrderMutations } from './orders.reducer';
import { OrdersState } from './orders.state';

const actions: ActionContext<StoreState, OrderMutations> = {
  createOrder: async function ({ mutate, rootState }, payload: { data: OrderTemplate; refresh: boolean }) {
    mutate(OrderMutations.START_LOADING, 'create_new');
    try {
      await api.orders.create({ body: payload.data });
      if (payload.refresh) {
        await this.fetchPendingOrders({ mutate, rootState });
      }
    } catch (e) {
      throw new Error('Error submitting new order.');
    } finally {
      mutate(OrderMutations.STOP_LOADING, 'create_new');
    }
  },
  getProductTemplate: async function ({ mutate }) {
    mutate(OrderMutations.START_LOADING, 'create_new');
    try {
      const { data } = await api.resources.templates.getInputTemplate({ params: { templateId: 'undefined' } });
      mutate(OrderMutations.SET_PRODUCT_INPUT_TEMPLATE, data);
    } catch (e) {
      throw new Error('Error fetching product schema.');
    } finally {
      mutate(OrderMutations.STOP_LOADING, 'create_new');
    }
  },
  fetchPendingOrders: async function ({ mutate, rootState }, payload: { page?: number; limit?: number } = {}) {
    mutate(OrderMutations.START_LOADING, 'pending');

    const page = payload.page !== undefined ? payload.page : rootState.orders.pending.page > -1 ? rootState.orders.pending.page : 0;
    const limit = payload.limit !== undefined ? payload.limit : rootState.orders.pending.limit;

    try {
      const { data } = await api.orders.pending.fetchAll({
        query: {
          page,
          limit,
          showAll: false,
          sort: [
            rootState.orders.pending.sortedBy['metadata.orderDate'] === SortingModes.ASC
              ? SortingKeys.ORDER_DATE
              : SortingKeys.ORDER_DATE_DESC,
          ],
        },
      });

      mutate(OrderMutations.SET_ORDERS, {
        itemSet: 'pending',
        items: data.data,
        page: data.page,
        limit,
        totalItems: data.totalItems,
      });
    } catch (e) {
      throw new Error('Error fetching pending products.');
    } finally {
      mutate(OrderMutations.STOP_LOADING, 'pending');
    }
  },
  sortPendingOrders: async function ({ mutate, rootState }, payload: { by: keyof OrdersState['pending']['sortedBy']; mode: SortingModes }) {
    rootState.orders.pending.sortedBy[payload.by] = payload.mode;
    await this.fetchPendingOrders({ mutate, rootState });
    mutate(OrderMutations.SET_SORTING, { itemSet: 'pending', key: payload.by, sortMode: payload.mode });
  },
  deletePendingOrder: async function ({ mutate, rootState }, payload: { orderId: string; refresh: boolean }) {
    mutate(OrderMutations.START_LOADING, 'pending');
    try {
      await api.orders.pending.delete({ params: { orderId: payload.orderId } });
      if (payload.refresh) await this.fetchPendingOrders({ mutate, rootState });
    } catch (e) {
      throw new Error('Error deleting order.');
    } finally {
      mutate(OrderMutations.STOP_LOADING, 'pending');
    }
  },
  fetchArchivedOrders: async function ({ mutate, rootState }, payload: { page?: number; limit?: number }) {
    mutate(OrderMutations.START_LOADING, 'archived');

    const page = payload.page !== undefined ? payload.page : rootState.orders.archived.page > -1 ? rootState.orders.archived.page : 0;
    const limit = payload.limit !== undefined ? payload.limit : rootState.orders.archived.limit;

    try {
      const { data } = await api.orders.archived.fetchAll({
        query: {
          page,
          limit,
          /*sort: [
            rootState.orders.archived.sortedBy['metadata.orderDate'] === SortingModes.ASC
              ? SortingKeys.ORDER_DATE
              : SortingKeys.ORDER_DATE_DESC,
          ],*/
        },
      });

      mutate(OrderMutations.SET_ORDERS, {
        itemSet: 'archived',
        items: data.data,
        page: data.page,
        totalItems: data.totalItems,
      });
    } catch (e) {
      throw new Error('Error fetching archived orders.');
    } finally {
      mutate(OrderMutations.STOP_LOADING, 'archived');
    }
  },
  sortArchivedOrders: async function (
    { mutate, rootState },
    payload: { by: keyof OrdersState['archived']['sortedBy']; mode: SortingModes; limit: number },
  ) {
    rootState.orders.archived.sortedBy[payload.by] = payload.mode;
    await this.fetchArchivedOrders({ mutate, rootState });
    mutate(OrderMutations.SET_SORTING, { itemSet: 'archived', key: payload.by, sortMode: payload.mode });
  },
};

export default actions;
