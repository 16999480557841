import { useWebSocket } from '@app:services';
import { useStore } from '@app:store';
import { Device, ProductionRequest } from '@app:types';
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Progress,
  Spacer,
  Spinner,
  StackDivider,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { Modal, SimplePageTemplate } from '@components/layout';
import { DeviceStatus } from '@ellure/types';

import { RiBilibiliLine, RiLineChartLine, RiMore2Fill, RiSignalWifiOffLine, RiZzzLine } from 'react-icons/ri';
import { AssignProductionToDeviceForm } from './forms/assign-production-to-device';

const Content = () => {
  const [store] = useStore();
  return (
    <VStack w='100%' h='100%' spacing={3} divider={<StackDivider />} overflowX={'auto'}>
      {store.location.devices.length > 0 ? (
        store.location.devices.map((device) => <DeviceTimelineRow {...device} key={device.id} />)
      ) : (
        <Center h='100%'>
          <Spinner size='lg' />
        </Center>
      )}
    </VStack>
  );
};

const DeviceTimelineRow = (props: Device) => {
  return (
    <Flex w='100%' height={'200px'} gap={3}>
      <Flex width='300px' flexDir='column' bgColor='white' borderColor='gray.200' borderWidth={1}>
        <Flex alignItems='center' gap='10px' p='5px' pl={3} borderBottom='2px' borderColor='gray.200'>
          <Icon borderRadius='full' as={RiBilibiliLine} h='20px' w='20px' />
          <Heading size='md'>{props.settings.name}</Heading>
          <Spacer />
          <Badge w={'70px'} p={2} textAlign={'center'}>
            {props.status}
          </Badge>
          <ButtonGroup>
            <Menu>
              <MenuButton
                size={'sm'}
                as={IconButton}
                icon={<RiMore2Fill />}
                variant='outline'
                aria-label='options'
                isDisabled={props.status === DeviceStatus.OFFLINE}
              />
              <MenuList>
                <MenuItem>Shutdown</MenuItem>
              </MenuList>
            </Menu>
          </ButtonGroup>
        </Flex>
        {(props as any).actionProgress && (
          <Flex alignItems='center' borderColor='gray.200' borderWidth='1px'>
            <Progress border={0} size='lg' hasStripe flex={1} value={(props as any).actionProgress.value} bg='white' />
          </Flex>
        )}
        <Flex alignItems='center' gap={6} height='100%' w='100%' minHeight='100px'>
          <ButtonGroup flex={1} gap={1} height='100%'>
            <Flex alignItems='stretch' w='100%'>
              <Button borderRadius={0} h='100%' w='100%' disabled>
                <RiSignalWifiOffLine />
              </Button>
            </Flex>
          </ButtonGroup>
        </Flex>
      </Flex>
      <Box overflowX={'auto'} overflowY={'hidden'} flex={1} p={3} bgColor='gray.200' borderColor='gray.200' borderWidth={1}>
        {props.queue.length === 0 ? (
          <Center height={'100%'} opacity={0.3}>
            <Text flex={1} textAlign={'center'}>
              <Icon h={'80px'} w={'80px'} as={RiZzzLine} /> <br />
              Waiting for new tasks...
            </Text>
          </Center>
        ) : (
          <HStack height={'100%'}>
            {props.productData && <ProductionRequestCard {...props.productData} isInProduction />}
            {props.queue.map((request, i) => (
              <ProductionRequestCard {...request} key={request.productId} isNext={i === 0} />
            ))}
          </HStack>
        )}
      </Box>
    </Flex>
  );
};

const ProductionRequestCard = (props: ProductionRequest & { isNext?: boolean; isInProduction?: boolean }) => {
  const websocket = useWebSocket();
  const { isOpen: isDetailsOpen, onClose: onDetailsClose, onOpen: onDetailsOpen } = useDisclosure();
  const { isOpen: isAssignmentOpen, onClose: onAssignmentClose, onOpen: onAssignmentOpen } = useDisclosure();

  return (
    <>
      <Card variant={'elevated'} minW='300px' height={'100%'} bgColor={props.isInProduction ? 'blue.50' : 'white'}>
        <CardHeader>
          <Flex alignItems='center' gap='10px'>
            <Heading size='md'>{props.metadata.derivedFrom.formulatedShade}</Heading>
            <Spacer />
            <Badge w={'70px'} p={2} textAlign={'center'}>
              {props.type}
            </Badge>
            {props.isInProduction ? (
              <Spinner />
            ) : (
              <ButtonGroup>
                <Menu>
                  <MenuButton size={'sm'} as={IconButton} icon={<RiMore2Fill />} variant='outline' aria-label='options' />
                  <MenuList>
                    {!props.isNext && (
                      <MenuItem
                        onClick={() =>
                          websocket.send({ type: 'user.moveTaskToFront', payload: { productId: props.productId, orderId: props.orderId } })
                        }
                      >
                        Prioritise task
                      </MenuItem>
                    )}
                    <MenuItem onClick={onAssignmentOpen}>Assign to other device</MenuItem>
                    <MenuItem
                      onClick={() =>
                        websocket.send({ type: 'user.deleteTask', payload: { productId: props.productId, orderId: props.orderId } })
                      }
                    >
                      Delete task
                    </MenuItem>
                  </MenuList>
                </Menu>
              </ButtonGroup>
            )}
          </Flex>
        </CardHeader>
        <CardBody py={0}>
          <ButtonGroup>
            <Button size={'sm'} variant={'outline'} onClick={onDetailsOpen}>
              View Details
            </Button>
            <Button size={'sm'} variant={'outline'}>
              View Order
            </Button>
          </ButtonGroup>
        </CardBody>
        <CardFooter>
          <Text fontSize={'sm'}>{props.isInProduction ? <i>Produced on the device.</i> : <i>Created x days ago.</i>}</Text>
        </CardFooter>
      </Card>
      <Modal
        isOpen={isDetailsOpen}
        onClose={onDetailsClose}
        title='Product information'
        modalContentStyle={{ maxWidth: '90%', width: '800px' }}
      >
        <VStack spacing={6}>
          <Box w='100%'>
            <Heading p={4} pb={2} as={'h2'} size={'md'}>
              General
            </Heading>
            <Table>
              <Tbody>
                <Tr>
                  <Td>Order ID</Td>
                  <Td>{props.orderId}</Td>
                  <Td>
                    <Button variant={'outline'}>View</Button>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Product ID</Td>
                  <Td>{props.productId}</Td>
                  <Td>{props.isInProduction ? <Badge p={2}>In production</Badge> : <Badge p={2}>Pending</Badge>}</Td>
                </Tr>
                {props.expected?.weight && (
                  <Tr>
                    <Td>Weight</Td>
                    <Td>{props.expected.weight}</Td>
                    <Td></Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Box>
          <Box w='100%' p={4}>
            <Heading pb={2} as={'h2'} size={'md'}>
              Expected formulation
            </Heading>
            <VStack spacing={4}>
              {props.expected?.formulation &&
                Object.entries(props.expected.formulation).map(([materialId, quantity]: [string, any]) => {
                  /*console.log(props);
                  let percentage: number;
                  if (quantity.unit === InnerProductUnits.PERCENT) percentage = quantity.amount;
                  else {
                    const weight = props.expected?.weight || 1;
                    percentage = quantity.amount / weight;
                  }*/
                  let percentage: number = quantity * 100;
                  return (
                    <Flex key={materialId} w='100%' p={4} gap={5} alignItems={'center'}>
                      <Text flex={2}>
                        {materialId} (~{percentage.toFixed(0)}%)
                      </Text>
                      <Progress flex={3} value={percentage} />
                    </Flex>
                  );
                })}
            </VStack>
          </Box>
        </VStack>
      </Modal>
      <Modal
        isOpen={isAssignmentOpen}
        onClose={onAssignmentClose}
        title='Assign production to device'
        modalContentStyle={{ maxWidth: '90%', width: '400px' }}
      >
        <AssignProductionToDeviceForm
          productionRequestRef={{ orderId: props.orderId, productId: props.productId }}
          onSubmitSuccess={() => onAssignmentClose()}
        />
      </Modal>
    </>
  );
};

const page = () => {
  return (
    <SimplePageTemplate
      {...{
        title: 'Production timeline',
        icon: RiLineChartLine,
      }}
      content={<Content />}
    />
  );
};

export default page;
