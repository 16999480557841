import {
  Device as DeviceDto,
  PendingOrder as PendingOrderDto,
  PendingProduct,
  ProductionRequest as ProductionRequestDto,
} from '@ellure/types';

export type PendingProductInventory = {
  automatic: PendingProduct[];
  manual: PendingProduct[];
  unprocessed: PendingProduct[];
  requested: PendingProduct[];
  completed: PendingProduct[];
};

export type PendingOrder = Omit<PendingOrderDto, 'content'> & {
  products: PendingProductInventory;
};

export type ProductionRequest = ProductionRequestDto;

export type Device = DeviceDto & {
  queue: ProductionRequest[];
};

export type UserClaims = {
  [key: string]: string | null;
  companyId: string | null;
};

export enum SortingModes {
  NONE = 0,
  DESC = -1,
  ASC = 1,
}

export * from './store';
