import { Device } from '@app:types';

export type LocationsState = {
  id: string;
  name: string;
  devices: Device[];
};

export const initialState: LocationsState = {
  id: null!,
  name: 'Loading...',
  devices: [],
};
