import { ActionContext, UserClaims } from '@app:types';
import { Client } from '@ellure/api-client-admin';
import { StoreState } from '@store/store';
import { AuthenticationMutations } from './authentication.reducer';

const actions: ActionContext<StoreState, AuthenticationMutations> = {
  async initialize({ mutate }, payload: { token: string; claims: UserClaims }): Promise<boolean> {
    mutate(AuthenticationMutations.SET_TOKEN, payload.token);
    mutate(AuthenticationMutations.SET_CLAIMS, payload.claims);
    Client.server.defaults.headers.common['x-api-key'] = payload.token;
    return true;
  },

  async destroy({ mutate }): Promise<boolean> {
    delete Client.server.defaults.headers.common['x-api-key'];
    mutate(AuthenticationMutations.RESET);
    return true;
  },
};

export default actions;
