import { ServerActions, WebsocketAction } from '@app:services';
import { UseToastIntercept } from '@app:store';
import { ActionContext, ProductionRequest } from '@app:types';
import { ProductionLocationState } from '@ellure/types';
import { StoreState } from '@store/store';
import { LocationMutations } from './locations.reducer';

const actions: ActionContext<StoreState, LocationMutations> = {
  async set({ mutate }, payload: { id: string; name?: string }) {
    mutate(LocationMutations.SET_LOCATION, payload);
  },
  async processMessage(injectee, action: WebsocketAction<ServerActions>) {
    switch (action.type) {
      case 'location.update':
        await this.update(injectee, action.payload);
        break;
      case 'location.notify':
        throw new UseToastIntercept({
          duration: 3000,
          isClosable: true,
          title: action.payload.action,
          description: action.payload.description,
          status: action.payload.type || 'info',
        });
    }
  },
  async update({ mutate }, payload: ProductionLocationState) {
    const queues = payload.queue.reduce<Record<string, ProductionRequest[]>>((map, item: any) => {
      const deviceId = item.deviceId;
      if (deviceId in map) {
        map[deviceId].push(item.request);
      } else {
        map[deviceId] = [item.request];
      }
      return map;
    }, {});

    payload.devices.forEach((device) =>
      mutate(LocationMutations.CREATE_OR_UPDATE_DEVICE, {
        ...device,
        queue: queues[device.id] || [],
      }),
    );
  },
};

export default actions;
