import { UserClaims } from '@app:types';

export type AuthenticationState = {
  isAuthenticated: boolean;
  token: string | null;
  claims: UserClaims;
};

export const initialState: AuthenticationState = {
  isAuthenticated: false,
  token: null,
  claims: {
    companyId: null,
  },
};
