import { PendingOrder, SortingModes } from '@app:types';
import { Order } from '@ellure/types';

export type OrdersState = {
  isCreatingNew: boolean;
  pending: {
    totalItems: number;
    isLoading: boolean;
    page: number;
    limit: number;
    items: PendingOrder[];
    sortedBy: {
      'metadata.orderDate': SortingModes;
    };
    lastUpdated: string | null;
    productInputTemplate: Record<string, { label: string; type: 'string' }> | null;
  };
  archived: {
    totalItems: number;
    isLoading: boolean;
    page: number;
    limit: number;
    items: Order[];
    sortedBy: {
      'metadata.orderDate': SortingModes;
    };
    lastUpdated: string | null;
  };
};

export const initialState: OrdersState = {
  isCreatingNew: false,
  pending: {
    isLoading: false,
    items: [],
    page: -1,
    limit: 10,
    totalItems: 0,
    sortedBy: {
      'metadata.orderDate': SortingModes.ASC,
    },
    lastUpdated: null,
    productInputTemplate: null,
  },
  archived: {
    isLoading: false,
    items: [],
    page: -1,
    limit: 10,
    totalItems: 0,
    sortedBy: {
      'metadata.orderDate': SortingModes.ASC,
    },
    lastUpdated: null,
  },
};
