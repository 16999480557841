import React from 'react';

import api from '@ellure/api-client-admin';
import { ProductTemplate, ProductTemplateAttachmentCategories } from '@ellure/types';

import { useEffectPostMount } from '@utils/hooks';

import { UpdateProductTemplateForm } from '@forms';

import { Button, ButtonGroup, useDisclosure } from '@chakra-ui/react';
import { FileManager, Modal } from '@components';
import { HeaderActionButton, ListTableColumn, ListView } from '@components/data-display/list-view';

import { RiFileEditLine } from 'react-icons/ri';

function ActionButtons(
  props: ProductTemplate & {
    onChange: () => void;
  },
) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();

  return (
    <>
      <ButtonGroup>
        <Button
          size='sm'
          variant='outline'
          colorScheme='blue'
          onClick={onOpen}
          isDisabled={(props.specifications as any).forceGhostProcessing}
        >
          Edit
        </Button>
        <Button
          size='sm'
          variant='outline'
          colorScheme='blue'
          onClick={onOpen2}
          isDisabled={(props.specifications as any).forceGhostProcessing}
        >
          <RiFileEditLine />
        </Button>
      </ButtonGroup>
      <Modal
        title={`Update '${props.productTemplateDisplayName}'`}
        isOpen={isOpen}
        onClose={onClose}
        modalContentStyle={{ maxW: '800px', w: '90%' }}
      >
        <UpdateProductTemplateForm
          refId={props.productTemplateId}
          defaultValues={{
            name: props.productTemplateDisplayName,
            specificationsFeedstock: props.specifications.feedstock,
            specificationsIngredients: props.specifications.ingredients,
            specificationsWeight: props.specifications.weight,
            options: {
              forceManual: props.specifications.forceManualProcessing,
            },
          }}
          onSubmit={() => {
            props.onChange();
            onClose();
          }}
        />
      </Modal>
      <Modal
        isOpen={isOpen2}
        onClose={onClose2}
        title='Manage attachments'
        modalContentStyle={{ maxW: '800px', w: '90%' }}
      >
        <FileManager<typeof ProductTemplateAttachmentCategories>
          categories={['CPSR', 'PIF', 'Other']}
          files={props.attachments as any}
          onUpload={async (body) => {
            await api.resources.templates.uploadAttachment({
              body,
              params: { templateId: props.productTemplateId },
            });
            props.onChange();
          }}
          onDelete={async (filename) => {
            await api.resources.templates.deleteAttachment({
              params: { templateId: props.productTemplateId, filename },
            });
            props.onChange();
          }}
        />
      </Modal>
    </>
  );
}

export function ProductTemplateList(props: {
  limit: number;
  headerActions: HeaderActionButton[];
  simple: boolean;

  isInput: boolean;
  selected?: string[];
  onSelect?: (value: string[]) => void;
  refresh: boolean;
}) {
  const [refresh, setRefresh] = React.useState(!!props.refresh);

  useEffectPostMount(() => {
    setRefresh((v) => !v);
  }, [props.refresh]);

  const columns: ListTableColumn<ProductTemplate>[] = props.simple
    ? [
        {
          key: 'productTemplateDisplayName',
          title: 'Template name',
        },
      ]
    : [
        {
          key: '@action',
          title: 'actions',
          render: (data, item) => <ActionButtons {...item} onChange={() => setRefresh(!refresh)} />,
          props: {
            w: '200px',
          },
        },
        {
          key: 'specifications',
          title: 'Type',
          render: (val) => {
            if (val.forceGhostProcessing) return 'Ghost';
            if (val.forceManualProcessing) return 'Manual';
            return 'Automatic';
          },
        },
        {
          key: 'productTemplateDisplayName',
          title: 'Template name',
        },
        {
          key: 'specifications.weight',
          title: 'Specified size',
          render: (val) => parseFloat(val).toFixed(2) + ' gram',
        },
        {
          key: 'specifications.feedstock.length',
          title: 'Specified components',
        },
      ];

  return (
    <>
      <ListView<ProductTemplate>
        limit={props.limit}
        refresh={refresh}
        emptyMessage={'No feedstock defined'}
        dataApi={async (currentPage: number) => {
          const response = await api.resources.templates.get({
            query: { page: currentPage, limit: props.limit, ghost: true } as any,
          });
          console.log(response);
          return response.data;
        }}
        dataId='productTemplateId'
        title='Product list'
        headerActions={props.headerActions}
        headerVisible={!props.simple}
        columns={columns}
        rowProps={(item) => ({
          opacity: (item.specifications as any).forceGhostProcessing ? 0.5 : 1,
        })}
        controlled={
          props.isInput
            ? {
                selectable: true,
                selected: props.selected!,
                onSelect: props.onSelect!,
              }
            : undefined
        }
      />
    </>
  );
}

ProductTemplateList.defaultProps = {
  simple: false,
  headerActions: [],
  isInput: false,
};
