import { ReducerContext, UserClaims } from '@app:types';
import { AuthenticationState, initialState } from './authentication.state';

export enum AuthenticationMutations {
  SET_TOKEN = 'set-token',
  SET_CLAIMS = 'set-user-claims',
  RESET = 'reset',
}

const reducers: ReducerContext<AuthenticationState> = {
  [AuthenticationMutations.RESET]: function (state) {
    state = initialState;
  },
  [AuthenticationMutations.SET_TOKEN]: function (state, payload: string) {
    state.token = payload;
    state.isAuthenticated = true;
  },
  [AuthenticationMutations.SET_CLAIMS]: function (state, payload: UserClaims) {
    state.claims = payload;
  },
};

export default reducers;
