import React from 'react';

import * as Yup from 'yup';
import api from '@ellure/api-client-admin';
import { CompoundRatio, InnerProductUnits, Material } from '@ellure/types';

import { useFormik } from 'formik';

import { Form } from '@components';
import {
  Button,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Select,
  Switch,
  Thead,
  Th,
  Tbody,
  Td,
  Table,
  Tr,
  Box,
  useToast,
  Textarea,
  Flex,
  FormLabel,
} from '@chakra-ui/react';
import { BoxLoader } from '@components/layout';

import { RiDeleteBin2Line } from 'react-icons/ri';

type TemplateInputOptions = {
  forceManual: boolean;
};

type TemplateInputSchema = {
  name: string;
  specificationsWeight: number;
  specificationsIngredients: string;
  specificationsFeedstock: CompoundRatio[];
  options: TemplateInputOptions;
};

function ProductTemplateFormBase(props: {
  defaultValues: TemplateInputSchema;
  onSubmit: (values: TemplateInputSchema) => Promise<void>;
  submitLabel: string;
}) {
  const toast = useToast();
  const form = useFormik<TemplateInputSchema>({
    initialValues: props.defaultValues,
    validationSchema: Yup.object().shape({
      name: Yup.string().required(),
      specificationsWeight: Yup.number().required(),
      specificationsIngredients: Yup.string().required(),
    }),
    onSubmit: async (values, helpers) => {
      try {
        await props.onSubmit(values);
        helpers.resetForm();
      } catch (e) {
        toast({
          title: 'Submission failed.',
          description: 'Your action did not succeed.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    },
  });

  return (
    <Form<TemplateInputSchema>
      {...form}
      submitLabel={props.submitLabel}
      schema={[
        {
          label: 'Product name',
          name: 'name',
        },
        {
          label: 'Product size',
          name: 'specificationsWeight',
          render: (value, handleChange) => {
            const format = (val: number) => val.toFixed(2) + ' gram';
            const parse = (val: string) => parseFloat(val.replace(/ gram/, ''));

            return (
              <NumberInput
                onChange={(valueString) => handleChange(parse(valueString))}
                value={format(value as number)}
                min={0}
                step={0.1}
                precision={2}
                max={50}
                variant='filled'
              >
                <NumberInputField type='text' />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            );
          },
        },
        {
          label: 'Product ingredient list',
          name: 'specificationsIngredients',
          render: (val, handleChange) => (
            <Textarea
              onChange={(e) => handleChange(e.target.value)}
              value={val as string}
              resize={'none'}
              variant='filled'
            />
          ),
        },
        {
          label: 'Product material components',
          name: 'specificationsFeedstock',
          render: (value, handleChange) => {
            return <CompoundRatioInputField value={value as CompoundRatio[]} onChange={handleChange} />;
          },
        },
        {
          label: 'Options',
          name: 'options',
          render: (value, handleChange) => {
            const onChange = (e: any) => {
              handleChange({
                forceManual: e.target.checked,
              });
            };

            return (
              <Stack>
                <Flex bgColor='gray.100' borderRadius='md' p={4} gap={4} alignItems='center'>
                  <Switch
                    id='forceManual'
                    isChecked={(value as TemplateInputOptions).forceManual}
                    onChange={onChange}
                  />
                  <FormLabel htmlFor='forceManual' m={0}>
                    Force products to be processed manually.
                  </FormLabel>
                </Flex>
              </Stack>
            );
          },
        },
      ]}
    />
  );
}

function CompoundRatioInputField(props: { value: CompoundRatio[]; onChange: (value: any) => void }) {
  const [materialOptions, setMaterialOptions] = React.useState<Material[]>([]);

  React.useEffect(() => {
    api.resources.materials.get({ query: { page: 0, limit: 60 } }).then((response) => {
      setMaterialOptions(response.data.data);
    });
  }, []);

  const handleChange = (index: number, value: CompoundRatio) => {
    const newValue = [...props.value];
    newValue[index] = value;
    props.onChange(newValue);
  };

  const handleRemove = (index: number) => {
    const newValue = [...props.value];
    newValue.splice(index, 1);
    props.onChange(newValue);
  };

  return (
    <BoxLoader isLoading={materialOptions.length === 0}>
      <Stack>
        <Table>
          <Thead>
            <Th w='calc(100% - 390px)' p={1} pl={3}>
              Materials
            </Th>
            <Th w='150px' p={1} pl={3}>
              Known quantity?
            </Th>
            <Th w='120px' p={1} pl={3}>
              Quantity
            </Th>
            <Th w='120px' p={1} pl={3}>
              Unit
            </Th>
            <Th w='50px' p={1} pl={3} />
          </Thead>
          <Tbody>
            {props.value.map((val, index) => (
              <FeedstockRatioInputRow
                key={index}
                value={val}
                materialOptions={materialOptions}
                onChange={handleChange.bind(null, index)}
                onRemove={handleRemove.bind(null, index)}
              />
            ))}
          </Tbody>
        </Table>
        <Button
          disabled={materialOptions.length === 0}
          onClick={() => {
            const newValue = [...props.value];
            newValue.push({
              materialId: materialOptions[0].materialId,
              amount: 'unknown',
            });
            props.onChange(newValue);
          }}
        >
          + Add component
        </Button>
      </Stack>
    </BoxLoader>
  );
}

function FeedstockRatioInputRow(props: {
  value: CompoundRatio;
  materialOptions: Material[];
  onChange: (value: CompoundRatio) => void;
  onRemove: () => void;
}) {
  const isUnknown = props.value.amount === 'unknown';

  const handleChange = (key: string, event: any) => {
    const newValue = JSON.parse(JSON.stringify(props.value));
    if (key === 'switch') {
      if (event.target.checked) {
        newValue.amount = 'unknown';
        newValue.unit = undefined;
      } else {
        newValue.amount = 0;
        newValue.unit = InnerProductUnits.GRAM;
      }
    } else if (key === 'amount') newValue[key] = event;
    else newValue[key] = event.target.value;

    props.onChange(newValue);
  };

  return (
    <Tr>
      <Td p={1} pl={3}>
        <Select
          onChange={handleChange.bind(null, 'materialId')}
          flex={1}
          value={props.value.materialId}
          variant='filled'
        >
          {props.materialOptions.map((item) => (
            <option key={item.materialId} value={item.materialId}>
              {item.displayName}
            </option>
          ))}
        </Select>
      </Td>
      <Td p={1} pl={3}>
        <Switch onChange={handleChange.bind(null, 'switch')} isChecked={isUnknown} />
      </Td>
      <Td p={1} pl={3}>
        <NumberInput
          isDisabled={isUnknown}
          onChange={handleChange.bind(null, 'amount')}
          value={!isUnknown ? props.value.amount : 0}
          min={0}
          step={0.1}
          precision={2}
          max={props.value.unit === InnerProductUnits.PERCENT ? 100 : 50}
          variant='filled'
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </Td>
      <Td p={1} pl={3}>
        <Select
          onChange={handleChange.bind(null, 'unit')}
          flex={1}
          variant='filled'
          value={props.value.unit}
          disabled={isUnknown}
        >
          <option value={InnerProductUnits.GRAM}>gram</option>
          <option value={InnerProductUnits.PERCENT}>%</option>
        </Select>
      </Td>
      <Td p={1} pl={3}>
        <Button variant='ghost' p={0} size='sm' onClick={props.onRemove}>
          <RiDeleteBin2Line />
        </Button>
      </Td>
    </Tr>
  );
}

export function UpdateProductTemplateForm(props: {
  refId: string;
  defaultValues: TemplateInputSchema;
  onSubmit?: () => void;
}) {
  return (
    <>
      <ProductTemplateFormBase
        submitLabel='Update'
        onSubmit={async (values) => {
          await api.resources.templates.update({
            body: {
              name: values.name,
              specificationsFeedstock: values.specificationsFeedstock,
              specificationsIngredients: values.specificationsIngredients,
              specificationsWeight: values.specificationsWeight,
              specificationsForceManual: values.options.forceManual,
            },
            params: { templateId: props.refId },
          });
          if (typeof props.onSubmit === 'function') props.onSubmit();
        }}
        defaultValues={props.defaultValues}
      />
      <Box m={4} mt={0}>
        <Button disabled={true} variant='outline' w='100%' colorScheme='red'>
          Delete
        </Button>
      </Box>
    </>
  );
}

export function CreateProductTemplateForm(props: { onSubmit?: () => void }) {
  return (
    <ProductTemplateFormBase
      submitLabel='Create'
      onSubmit={async (values) => {
        await api.resources.templates.create({
          body: {
            name: values.name,
            specificationsFeedstock: values.specificationsFeedstock,
            specificationsIngredients: values.specificationsIngredients,
            specificationsWeight: values.specificationsWeight,
            specificationsForceManual: values.options.forceManual,
          },
        });
        if (typeof props.onSubmit === 'function') props.onSubmit();
      }}
      defaultValues={{
        name: '',
        specificationsWeight: 0,
        specificationsIngredients: '',
        specificationsFeedstock: [],
        options: {
          forceManual: false,
        },
      }}
    />
  );
}
