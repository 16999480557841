import { Badge, Button, Input, Stack, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { BoxLoader } from '@components/layout';

import { RiDeleteBin2Line } from 'react-icons/ri';

export type ProductInputValue = Record<string, string | number>;

export function parseProductNumber(index: number) {
  return `XXX-${(index + 1).toString().padStart(2, '0')}`;
}

export function ProductsInput(props: {
  value: ProductInputValue[];
  schema: Record<string, { label: string; type: 'string' }>;
  onChange: (value: ProductInputValue[]) => void;
}) {
  const handleChange = (index: number, value: ProductInputValue) => {
    const newValue = [...props.value];
    newValue[index] = value;
    props.onChange(newValue);
  };

  const handleDelete = (index: number) => {
    if (props.value.length > 1) {
      const newValue = [...props.value];
      newValue.splice(index, 1);
      props.onChange(
        newValue.map((p, i) => ({
          ...p,
          productNumber: parseProductNumber(i),
        })),
      );
    }
  };

  return (
    <BoxLoader>
      <Stack>
        <Table>
          <Thead>
            <Th w='50px' p={1} pl={3}>
              Product #
            </Th>
            {Object.entries(props.schema).map(([key, column]) => (
              <Th key={key} w='100px' p={1} pl={3}>
                {column.label}
              </Th>
            ))}
            <Th w='50px' p={1} pl={3} />
          </Thead>
          <Tbody>
            {props.value.map((val, index) => (
              <OrderProductsInputRow
                key={index}
                value={val}
                schema={props.schema}
                onChange={handleChange.bind(null, index)}
                onDelete={handleDelete.bind(null, index)}
              />
            ))}
          </Tbody>
        </Table>
        <Button
          onClick={() => {
            const newValue = [...props.value];
            newValue.push(
              Object.entries(props.schema).reduce(
                (map, [key, column]) => {
                  map[key] = '';
                  return map;
                },
                {
                  productNumber: parseProductNumber(newValue.length),
                } as ProductInputValue,
              ),
            );
            props.onChange(newValue);
          }}
        >
          + Add product
        </Button>
      </Stack>
    </BoxLoader>
  );
}

function OrderProductsInputRow(props: {
  value: ProductInputValue;
  schema: Record<string, { label: string; type: 'string' }>;
  onChange: (value: ProductInputValue) => void;
  onDelete: () => void;
}) {
  const handleChange = (key: string, event: any) => {
    const newValue = JSON.parse(JSON.stringify(props.value)) as ProductInputValue;
    newValue[key] = event.target.value;
    props.onChange(newValue);
  };

  return (
    <Tr>
      <Td p={1} pl={3}>
        <Badge>{props.value.productNumber}</Badge>
      </Td>
      {Object.keys(props.schema).map((key) => {
        if (key === 'productNumber') return null;
        return (
          <Td key={key} p={1} pl={3}>
            <Input value={props.value[key]} onChange={handleChange.bind(null, key)} />
          </Td>
        );
      })}
      <Td p={1} pl={3}>
        <Button variant='ghost' p={0} size='sm' onClick={props.onDelete}>
          <RiDeleteBin2Line />
        </Button>
      </Td>
    </Tr>
  );
}
