import { Store } from '@app:types';
import authentication from './authentication/authentication.slice';
import { AuthenticationState } from './authentication/authentication.state';
import location from './locations/locations.slice';
import { LocationsState } from './locations/locations.state';
import orders from './orders/orders.slice';
import { OrdersState } from './orders/orders.state';

export type StoreState = {
  orders: OrdersState;
  location: LocationsState;
  authentication: AuthenticationState;
};

export default new Store<StoreState>({
  orders,
  location,
  authentication,
});
