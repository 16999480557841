import api from '@ellure/api-client-admin';
import { MaterialTypes } from '@ellure/types';

import { useFormik } from 'formik';

import { Form } from '@components';

type MaterialInputSchema = {
  name: string;
  type: MaterialTypes;
};

export function CreateMaterial(props: { onSubmit?: () => void }) {
  const form = useFormik<MaterialInputSchema>({
    initialValues: {
      name: '',
      type: MaterialTypes.feedstock,
    },
    onSubmit: async (values, helpers) => {
      await api.resources.materials.create({ body: values });
      helpers.resetForm();
      if (typeof props.onSubmit === 'function') props.onSubmit();
    },
  });

  return (
    <Form<MaterialInputSchema>
      {...form}
      schema={[
        {
          label: 'Material name',
          name: 'name',
        },
      ]}
    />
  );
}
