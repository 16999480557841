import { Slice } from '@app:types';
import { StoreState } from '@store/store';
import actions from './authentication.actions';
import reducers, { AuthenticationMutations } from './authentication.reducer';
import { AuthenticationState, initialState } from './authentication.state';

const slice: Slice<StoreState, AuthenticationState, AuthenticationMutations> = {
  name: 'authentication',
  state: initialState,
  reducers,
  actions,
};

export default slice;
