import * as Yup from 'yup';

import { useFormik } from 'formik';

import { useStore } from '@app:store';
import { Center, Spinner } from '@chakra-ui/react';
import { BoxLoader, Form, ProductInputSchema } from '@components';
import { parseProductNumber, ProductInputValue, ProductsInput } from '@components/forms/products-input-2';
import React from 'react';

export type OrderInputSchema = {
  name: string;
  email: string;
  orderDate: string;
  content: Record<string, string | number>[];
};
export function CreateOrderForm(props: { onSubmitSuccess?: () => void; defaultData?: Partial<OrderInputSchema> }) {
  const [store, dispatch] = useStore();
  const [productSchema, setProductSchema] = React.useState({});

  const form = useFormik<OrderInputSchema>({
    initialValues: {
      name: '',
      email: '',
      orderDate: new Date().toISOString().split('T')[0],
      content: props.defaultData?.content || [],
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(),
      email: Yup.string().required(),
      orderDate: Yup.string().required(),
      content: Yup.array().min(1, 'Order must have at least 1 product.'),
    }),
    onSubmit: async ({ content, ...values }, helpers) => {
      dispatch(
        'orders',
        'createOrder',
        {
          data: {
            metadata: values,
            content: content.map((product) => ({
              derivedFrom: product,
              type: 'lipstick',
            })),
          },
          refresh: true,
        },
        () => {
          helpers.resetForm();
          if (typeof props.onSubmitSuccess === 'function') props.onSubmitSuccess();
        },
      );
    },
  });

  React.useEffect(() => {
    const schema = store.orders.pending.productInputTemplate;
    if (schema !== null) {
      setProductSchema(schema);
      form.setFieldValue('content', [
        Object.entries(schema).reduce(
          (map, [key, column]) => {
            map[key] = '';
            return map;
          },
          {
            productNumber: parseProductNumber(0),
          } as ProductInputValue,
        ),
      ]);
    } else dispatch('orders', 'getProductTemplate');
  }, [store.orders.pending.productInputTemplate]);

  if (store.orders.pending.productInputTemplate === null) {
    return (
      <Center py={5}>
        <Spinner size={'lg'} />
      </Center>
    );
  }

  return (
    <BoxLoader isLoading={store.orders.isCreatingNew}>
      <Form<OrderInputSchema>
        {...form}
        submitLabel='Create'
        schema={[
          {
            label: 'Customer name',
            name: 'name',
          },
          {
            label: 'Customer email',
            name: 'email',
          },
          {
            label: 'Order date',
            name: 'orderDate',
            props: {
              type: 'date',
            },
          },
          {
            label: 'Products',
            name: 'content',
            render: (value, handleChange) => (
              <ProductsInput value={value as ProductInputSchema[]} schema={productSchema} onChange={handleChange} />
            ),
          },
        ]}
      />
    </BoxLoader>
  );
}
