import React from 'react';

import api from '@ellure/api-client-admin';
import { Material } from '@ellure/types';

import { CreateBatch } from '@forms';

import { Button, ButtonGroup, useDisclosure } from '@chakra-ui/react';
import { HeaderActionButton, ListTableColumn, ListView } from '@components/data-display/list-view';
import { Modal } from '@components/layout';
import { Link } from 'react-router-dom';

export function MaterialList(props: {
  limit: number;
  headerActions: HeaderActionButton[];
  simple: boolean;

  isInput: boolean;
  selected?: string[];
  refresh?: boolean;
  onSelect?: (value: string[]) => void;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [material, setMaterialId] = React.useState<Material | undefined>(undefined);

  const columns: ListTableColumn<Material>[] = props.simple
    ? [
        {
          key: 'displayName',
          title: 'Material name',
        },
      ]
    : [
        {
          key: '@action',
          title: 'actions',
          render: (data, item) => (
            <ButtonGroup>
              <Link to={item.materialId}>
                <Button size='sm' variant='outline' colorScheme='blue'>
                  View
                </Button>
              </Link>
              <Button
                size='sm'
                variant='outline'
                onClick={() => {
                  setMaterialId(item);
                  onOpen();
                }}
              >
                +Batch
              </Button>
            </ButtonGroup>
          ),
          props: {
            width: '200px',
          },
        },
        {
          key: 'type',
          title: 'type',
        },
        {
          key: 'displayName',
          title: 'Material name',
        },
      ];

  return (
    <>
      <ListView<Material>
        limit={props.limit}
        refresh={props.refresh}
        emptyMessage={'No feedstock defined'}
        dataApi={async (currentPage: number) => {
          const response = await api.resources.materials.get({ query: { page: currentPage, limit: props.limit } });
          console.log(response);
          return response.data;
        }}
        dataId='materialId'
        title='Feedstock list'
        headerActions={props.headerActions}
        headerVisible={!props.simple}
        columns={columns}
        controlled={
          props.isInput
            ? {
                selectable: true,
                selected: props.selected!,
                onSelect: props.onSelect!,
              }
            : undefined
        }
      />
      {material && (
        <Modal isOpen={isOpen} onClose={onClose} title={`Create a new ${material.displayName} batch`}>
          <CreateBatch materialId={material.materialId} onSubmit={() => onClose()} />
        </Modal>
      )}
    </>
  );
}

MaterialList.defaultProps = {
  simple: false,
  headerActions: [],
  isInput: false,
};
