import * as Yup from 'yup';
import api from '@ellure/api-client-admin';

import { useFormik } from 'formik';

import { Form } from '@components';
import { Box, Button, useToast } from '@chakra-ui/react';

type SupplierInputSchema = {
  name: string;
  email: string;
  address: string;
  postcode: string;
  city: string;
  country: string;
};

function SupplierFormBase(props: {
  defaultValues: SupplierInputSchema;
  onSubmit: (values: SupplierInputSchema) => Promise<void>;
  submitLabel: string;
}) {
  const toast = useToast();
  const form = useFormik<SupplierInputSchema>({
    initialValues: props.defaultValues,
    validationSchema: Yup.object().shape({
      name: Yup.string().min(5).required(),
      email: Yup.string().email().required(),
      address: Yup.string().min(1).required(),
      postcode: Yup.string().min(1).required(),
      city: Yup.string().min(1).required(),
      country: Yup.string().min(2).max(2).required(),
    }),
    onSubmit: async (values, helpers) => {
      try {
        await props.onSubmit(values);
        helpers.resetForm();
      } catch (e) {
        toast({
          title: 'Submission failed.',
          description: 'Your action did not succeed.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    },
  });

  return (
    <Form<SupplierInputSchema>
      {...form}
      submitLabel={props.submitLabel}
      schema={[
        {
          label: 'Supplier name',
          name: 'name',
        },
        {
          label: 'Supplier contact email',
          name: 'email',
        },
        {
          label: "Supplier's address",
          name: 'address',
        },
        {
          label: 'Supplier postcode',
          name: 'postcode',
        },
        {
          label: 'Supplier city',
          name: 'city',
        },
        {
          label: 'Supplier country',
          name: 'country',
        },
      ]}
    />
  );
}

export function UpdateSupplierForm(props: {
  refId: string;
  defaultValues: SupplierInputSchema;
  onSubmit?: () => void;
}) {
  return (
    <>
      <SupplierFormBase
        submitLabel='Update'
        onSubmit={async (values) => {
          await api.resources.suppliers.update({ body: values, params: { supplierId: props.refId } });
          if (typeof props.onSubmit === 'function') props.onSubmit();
        }}
        defaultValues={props.defaultValues}
      />
      <Box m={4} mt={0}>
        <Button disabled={true} variant='outline' w='100%' colorScheme='red'>
          Delete
        </Button>
      </Box>
    </>
  );
}

export function CreateSupplierForm(props: { onSubmit?: () => void }) {
  return (
    <SupplierFormBase
      submitLabel='Create'
      onSubmit={async (values) => {
        await api.resources.suppliers.create({ body: values });
        if (typeof props.onSubmit === 'function') props.onSubmit();
      }}
      defaultValues={{
        name: '',
        email: '',
        address: '',
        postcode: '',
        city: '',
        country: '',
      }}
    />
  );
}
