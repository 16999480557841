import { Alert, AlertDescription, AlertIcon, AlertTitle } from '@chakra-ui/react';

export default function page() {
  return (
    <Alert status='success'>
      <AlertIcon />
      <AlertTitle>You have successfully authenticated Shopify on Ellure.</AlertTitle>
      <AlertDescription>You can now close this window.</AlertDescription>
    </Alert>
  );
}
