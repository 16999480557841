import * as Yup from 'yup';

import { useFormik } from 'formik';

import { useWebSocket } from '@app:services';
import { useStore } from '@app:store';
import { Center, Select, Spinner } from '@chakra-ui/react';
import { BoxLoader, Form } from '@components';

export type AssignToMachineInputSchema = {
  deviceId: string;
};
export function AssignProductionToDeviceForm(props: {
  productionRequestRef: { orderId: string; productId: string };
  onSubmitSuccess?: () => void;
  defaultData?: Partial<AssignToMachineInputSchema>;
}) {
  const [store] = useStore();
  const websocket = useWebSocket();

  const form = useFormik<AssignToMachineInputSchema>({
    initialValues: {
      deviceId: store.location.devices[0].id || '',
      ...props.defaultData,
    },
    validationSchema: Yup.object().shape({
      deviceId: Yup.string().required(),
    }),
    onSubmit: async ({ deviceId }) => {
      websocket.send({
        type: 'user.moveTaskToMachine',
        payload: {
          deviceId,
          orderId: props.productionRequestRef.orderId,
          productId: props.productionRequestRef.productId,
        },
      });

      if (typeof props.onSubmitSuccess === 'function') props.onSubmitSuccess();
    },
  });

  if (store.location.devices.length === 0) {
    return (
      <Center py={5}>
        <Spinner size={'lg'} />
      </Center>
    );
  }

  return (
    <BoxLoader isLoading={store.orders.isCreatingNew}>
      <Form<AssignToMachineInputSchema>
        {...form}
        submitLabel='Assign'
        schema={[
          {
            label: 'Device',
            name: 'deviceId',
            render: (value, onChange) => (
              <Select value={value} onChange={(e) => onChange(e.target.value)}>
                {store.location.devices.map((device) => (
                  <option key={device.id} value={device.id}>
                    {device.id}
                  </option>
                ))}
              </Select>
            ),
          },
        ]}
      />
    </BoxLoader>
  );
}
