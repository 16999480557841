import { useParams } from 'react-router-dom';

import { MaterialBatchList } from '@lists';

import { SimplePageTemplate } from '@components/layout';
import { Box, Heading } from '@chakra-ui/react';
import Chart from 'react-apexcharts';

import { RiStackLine } from 'react-icons/ri';

const Content = () => {
  const { materialId } = useParams();

  return (
    <Box p={3} bg='white' borderColor='gray.200' borderWidth='1px' width='100%' h='100%'>
      <Box p={3} pb={3} pt={1}>
        <Heading size='sm'>Material Usage</Heading>
      </Box>
      <Chart
        options={{
          chart: {
            id: 'basic-bar',
            toolbar: { show: false },
            zoom: { enabled: false },
          },
          markers: {
            size: 6,
          },
          xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
          },
        }}
        series={[
          {
            name: 'Usage',
            data: [30, 40, 45, 50, 49, 60, 70, 91],
          },
        ]}
        type='line'
        width='100%'
        height='300px'
      />
      <MaterialBatchList limit={20} materialId={materialId as string} />
    </Box>
  );
};

export default function page() {
  return (
    <SimplePageTemplate
      {...{
        title: 'Material Details',
        icon: RiStackLine,
      }}
      content={<Content />}
    />
  );
}
