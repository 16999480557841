import { Device, ReducerContext } from '@app:types';
import { initialState, LocationsState } from './locations.state';

export enum LocationMutations {
  SET_LOCATION = 'set-location',
  CREATE_OR_UPDATE_DEVICE = 'create-or-update-device',
}

const reducers: ReducerContext<LocationsState> = {
  [LocationMutations.SET_LOCATION]: function (state: LocationsState, payload: { id?: string; name?: string }) {
    state.id = payload.id || initialState.id;
    state.name = payload.name || initialState.name;
  },
  [LocationMutations.CREATE_OR_UPDATE_DEVICE]: function (state: LocationsState, payload: Device) {
    const i = state.devices.findIndex((device) => device.id === payload.id);
    if (i === -1) state.devices.push(payload);
    else state.devices[i] = payload;
  },
};

export default reducers;
