import { useAuth } from '.';
import { useFormik } from 'formik';

import { Box, Button, Flex, FormControl, Image, Input, Stack, Text } from '@chakra-ui/react';

import logo from '@assets/ellure-logo.png';

export default function ChangePassword() {
  const { changePassword } = useAuth();
  const form = useFormik({
    initialValues: {
      oldPassword: '',
      password: '',
      password2: '',
    },
    onSubmit: async (values, helpers) => {
      try {
        await changePassword(values.password, values.oldPassword);
      } catch (e: any) {
        //helpers.setErrors(e.message);
      }
    },
  });

  return (
    <Box alignSelf='center' p={6} bg='white' w='100%' maxWidth='800px' borderColor='gray.200' borderWidth='1px'>
      <Flex flexDirection='column' alignItems='center' mx='14' pt='6' pb='2' justifyContent='space-between'>
        <Image src={logo} alt='Ellure Cloud Factory' width='100px' />
        <Text fontSize='sm'>Cloud Factory</Text>
        <form onSubmit={form.handleSubmit}>
          <Stack mt={6}>
            <FormControl>
              <Input
                placeholder='Old Password'
                id='oldPassword'
                name='oldPassword'
                type='password'
                variant='filled'
                onChange={form.handleChange}
                value={form.values.oldPassword}
              />
            </FormControl>
            <FormControl>
              <Input
                placeholder='New Password'
                id='password'
                name='password'
                type='password'
                variant='filled'
                onChange={form.handleChange}
                value={form.values.password}
              />
            </FormControl>
            <FormControl>
              <Input
                placeholder='Confirm Password'
                id='password2'
                name='password2'
                type='password'
                variant='filled'
                onChange={form.handleChange}
                value={form.values.password2}
              />
            </FormControl>
            <Button type='submit' colorScheme='blue'>
              Update password
            </Button>
          </Stack>
        </form>
      </Flex>
    </Box>
  );
}
