export function dateIsOlderThan(date: Date | string | null, threshold: number, unit: 's' | 'm' | 'h' | 'ms' = 'ms') {
  let compareDate: Date;
  if (!date) return true;
  if (typeof date === 'string') compareDate = new Date(date);
  else compareDate = date;

  const now = new Date();
  let thresholdInMs: number;

  switch (unit) {
    case 's':
      thresholdInMs = threshold * 1000;
      break;
    case 'm':
      thresholdInMs = threshold * 60 * 1000;
      break;
    case 'h':
      thresholdInMs = threshold * 60 * 60 * 1000;
      break;
    default: // 'milliseconds'
      thresholdInMs = threshold;
  }

  const timeDifference = now.getTime() - compareDate.getTime();
  return timeDifference >= thresholdInMs;
}
