import { Button, FormControl, FormLabel, Input, InputProps, Stack } from '@chakra-ui/react';
import { BoxLoader } from '@components/layout';
import { FormikErrors } from 'formik';

interface IInput<Schema, K extends keyof Schema> {
  label: string;
  name: K;
  props?: Omit<InputProps, 'onChange'>;
  render?: (
    value: Schema[K],
    handleChange: (value: any) => void,
    input: IInput<Schema, keyof Schema>,
  ) => React.ReactNode;
}

export function Form<Schema>(form: {
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  setFieldValue: (field: string, value: any) => void;
  isSubmitting: boolean;
  values: Schema;
  errors: FormikErrors<Schema>;
  schema: IInput<Schema, keyof Schema>[];
  submitLabel?: string;
}) {
  return (
    <form onSubmit={form.handleSubmit} noValidate>
      <BoxLoader isLoading={form.isSubmitting}>
        <Stack gap={3} p={4}>
          {form.schema.map((input) => {
            const value = form.values[input.name];
            const handler = form.setFieldValue.bind(null, input.name as string);
            return (
              <FormControl isInvalid={!!form.errors[input.name]}>
                <FormLabel>{input.label}</FormLabel>
                {input.render ? (
                  input.render(form.values[input.name], handler, input)
                ) : (
                  <Input
                    placeholder=''
                    variant='filled'
                    {...input.props}
                    id={input.name as string}
                    name={input.name as string}
                    onChange={form.handleChange}
                    value={value as any as string}
                  />
                )}
              </FormControl>
            );
          })}
          <Button type='submit' colorScheme='blue' disabled={form.isSubmitting}>
            {form.submitLabel}
          </Button>
        </Stack>
      </BoxLoader>
    </form>
  );
}

Form.defaultProps = {
  submitLabel: 'Create',
};
