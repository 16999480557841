import { IQuantity, InnerProductUnits, OuterProductUnits } from '@ellure/types';

import {
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
} from '@chakra-ui/react';

export function QuantityInput<Units = InnerProductUnits | OuterProductUnits>(props: {
  disabled: boolean;
  value: IQuantity<Units>;
  onChange: (value: IQuantity<Units>) => void;
}) {
  const handleChange = (key: string, event: any) => {
    const newValue = JSON.parse(JSON.stringify(props.value));
    if (key === 'amount') newValue[key] = event;
    else newValue[key] = event.target.value;

    props.onChange(newValue);
  };

  return (
    <Flex gap={3}>
      <NumberInput
        isDisabled={props.disabled}
        onChange={handleChange.bind(null, 'amount')}
        value={props.value.amount}
        min={0}
        step={0.1}
        precision={2}
        variant='filled'
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <Select
        onChange={handleChange.bind(null, 'unit')}
        flex={1}
        variant='filled'
        value={props.value.unit as string}
        disabled={props.disabled}
      >
        <option value={InnerProductUnits.GRAM}>gram</option>
        <option value={InnerProductUnits.MILLILITER}>mL</option>
      </Select>
    </Flex>
  );
}

QuantityInput.defaultProps = {
  disabled: false,
};
