import api from '@ellure/api-client-admin';
import { IQuantity, InnerProductUnits, MaterialTypes } from '@ellure/types';

import { useStore } from '@store';
import { useFormik } from 'formik';

import { Form, QuantityInput, SupplierSelectInput } from '@components';

type BatchInputSchema = {
  batchNumber: string;
  supplierId: string;
  expirationDate: string;
  quantity: IQuantity<InnerProductUnits>;
};

export function CreateBatch(props: { onSubmit?: () => void; materialId: string }) {
  const [store, dispatch] = useStore();

  const form = useFormik<BatchInputSchema>({
    initialValues: {
      batchNumber: '',
      supplierId: '',
      expirationDate: new Date().toISOString().split('T')[0],
      quantity: { amount: 0, unit: InnerProductUnits.GRAM },
    },
    onSubmit: async (values, helpers) => {
      await api.resources.batches.create({
        body: {
          type: MaterialTypes.feedstock,
          batchNumber: values.batchNumber,
          supplierId: values.supplierId,
          locationId: store.location.id,
          expirationDate: values.expirationDate,
          quantity: {
            amount: values.quantity.amount,
            unit: values.quantity.unit,
          },
        },
        params: {
          materialId: props.materialId,
        },
      } as any);
      if (typeof props.onSubmit === 'function') props.onSubmit();
      helpers.resetForm();
    },
  });

  return (
    <Form<BatchInputSchema>
      {...form}
      schema={[
        {
          label: 'Batch number',
          name: 'batchNumber',
        },
        {
          label: 'Quantity',
          name: 'quantity',
          render: (value, handleChange) => (
            <QuantityInput<InnerProductUnits> value={value as IQuantity<InnerProductUnits>} onChange={handleChange} />
          ),
        },
        {
          label: 'Expiration Date',
          name: 'expirationDate',
          props: {
            type: 'date',
          },
        },
        {
          label: 'Supplier',
          name: 'supplierId',
          render: (value, handleChange) => <SupplierSelectInput value={value as string} onChange={handleChange} />,
        },
      ]}
    />
  );
}
