import React from 'react';

import { ApiPaginationData } from '@ellure/api-client-admin';

import { Flex, Text, Button, ButtonGroup, Heading, Spacer, ButtonProps, TableRowProps } from '@chakra-ui/react';
import { BoxLoader } from '@components/layout';
import { DataTable, DataTableColumn } from '@components/data-display';

import { RiArrowLeftSLine, RiArrowRightSLine, RiRefreshLine, RiErrorWarningFill } from 'react-icons/ri';
import { useEffectPostMount } from '@utils/hooks';
import { SortingModes } from '@app:types';

export type HeaderActionButton = ButtonProps & { label: string };
export type ActionButton = Omit<ButtonProps, 'onClick'> & {
  label: string;
  onClick: (selection: string[]) => Promise<void>;
};

export type ListTableColumn<DataType> = Omit<DataTableColumn<DataType>, 'sort'>;

export function ListView<DataType>(props: {
  title: string;
  emptyMessage: string;
  actions: ActionButton[];
  headerActions: HeaderActionButton[];
  headerVisible: boolean;
  limit: number;
  dataApi: (currentPage: number, sorting: SortingModes[]) => Promise<ApiPaginationData<DataType>>;
  dataId: keyof DataType;
  refresh?: boolean;
  columns?: ListTableColumn<DataType>[];
  defaultSort?: SortingModes[];
  rowProps?: (item: DataType) => TableRowProps;
  controlled?: {
    selectable?: boolean;
    selected: string[];
    onSelect: (value: string[]) => void;
  };
}) {
  const [isLoading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<{ message: string } | undefined>(undefined);
  const [currentPage, setCurrentPage] = React.useState(0);

  const [selected, setSelectedOrders] = React.useState<string[]>([]);
  const [sortSettings, setSortSettings] = React.useState(
    props.defaultSort || props.columns?.map(() => SortingModes.NONE) || [],
  );

  const [response, setResponse] = React.useState<ApiPaginationData<DataType>>({
    page: 0,
    totalItems: 0,
    totalPages: 1,
    pageItems: 0,
    data: [],
  });

  const getData = React.useCallback(() => {
    setLoading(true);
    setError(undefined);

    props
      .dataApi(currentPage, sortSettings)
      .then((res) => {
        setResponse(res);
        setSelectedOrders([]);
      })
      .catch((e) => {
        setError({
          message: 'Error loading data.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props.dataApi, currentPage, sortSettings]);

  const handleSortSettings = React.useCallback((key: string, newValue: SortingModes) => {
    /*setSortSettings((settings) => {
      return settings.map((sort, i) => {
        if (i !== colIndex) return sort;
        if (sort === SortingModes.ASC) return SortingModes.DESC;
        return SortingModes.ASC;
      });
    });*/
  }, []);

  const executeAction = React.useCallback(
    (action: (selection: string[]) => Promise<void>) => {
      setLoading(true);
      action(selected)
        .then(() => {
          setCurrentPage(0);
          getData();
        })
        .finally(() => {
          setSelectedOrders([]);
          setLoading(false);
        });
    },
    [getData, selected],
  );

  React.useEffect(() => {
    getData();
  }, [currentPage, sortSettings]);

  useEffectPostMount(() => {
    getData();
  }, [props.refresh]);

  const { totalItems, totalPages, pageItems, data } = response;
  const firstItemIndex = Math.min(currentPage * props.limit, totalItems);

  return (
    <Flex flexDir={'column'} height='100%'>
      {props.headerVisible && (
        <Flex p={3} pb={3} pt={1} alignItems='center'>
          <Heading size='sm'>{props.title}</Heading>
          <Spacer />
          <ButtonGroup gap='0'>
            {props.headerActions.map(({ label, onClick, ...props }) => (
              <Button {...props} key={label} disabled={isLoading} onClick={onClick} size='sm'>
                {label}
              </Button>
            ))}
            <Button size='sm' onClick={getData} disabled={isLoading}>
              <RiRefreshLine />
            </Button>
          </ButtonGroup>
        </Flex>
      )}
      <BoxLoader flex={1} isLoading={isLoading}>
        <DataTable<DataType>
          emptyMessageIcon={error?.message ? RiErrorWarningFill : undefined}
          emptyMessage={error?.message || props.emptyMessage}
          selected={props.controlled ? props.controlled.selected : selected}
          onSelect={props.controlled ? props.controlled?.onSelect : setSelectedOrders}
          data={data}
          onSort={handleSortSettings}
          dataId={props.dataId}
          columns={props.columns?.map((col, i) => ({ ...col, sort: sortSettings[i] }))}
          rowProps={props.rowProps}
          selectable={props.controlled?.selectable || props.actions.length > 0}
        />
      </BoxLoader>
      <Flex p={3} pb={0} gap={6} alignItems='center' borderColor='gray.100' borderTopWidth='1px'>
        <ButtonGroup gap='0'>
          {props.actions.map(
            ({ label, onClick, ...props }: { label: string; onClick: (selection: string[]) => Promise<void> }) => (
              <Button
                {...props}
                key={label}
                disabled={selected.length === 0 || isLoading}
                onClick={executeAction.bind(null, onClick)}
              >
                {label}
              </Button>
            ),
          )}
        </ButtonGroup>
        <Spacer />
        <Text>
          {firstItemIndex} - {firstItemIndex + pageItems} of {totalItems}
        </Text>
        <ButtonGroup gap='0'>
          <Button
            size='sm'
            disabled={currentPage === 0 || isLoading}
            onClick={setCurrentPage.bind(null, Math.max(0, currentPage - 1))}
          >
            <RiArrowLeftSLine />
          </Button>
          <Button
            size='sm'
            disabled={currentPage === totalPages - 1 || isLoading}
            onClick={setCurrentPage.bind(null, Math.min(totalPages - 1, currentPage + 1))}
          >
            <RiArrowRightSLine />
          </Button>
        </ButtonGroup>
      </Flex>
    </Flex>
  );
}

ListView.defaultProps = {
  title: 'New List View',
  actions: [],
  headerActions: [],
  headerVisible: true,
};
