import React from 'react';
import { Box, Center, ChakraProps, Spinner } from '@chakra-ui/react';

export function BoxLoader(
  props: ChakraProps & {
    isLoading: boolean;
    children?: React.ReactNode | React.ReactNode[];
  },
) {
  const { isLoading, children, ..._props } = props;

  return (
    <Box {..._props} position='relative' cursor={isLoading ? 'progress' : 'default'}>
      {props.isLoading && (
        <Center position='absolute' h='100%' w='100%' zIndex='2'>
          <Box position='absolute' h='100%' w='100%' bg='rgba(247, 250, 252 ,0.90)' filter='blur(5px)' />
          <Spinner />
        </Center>
      )}
      {props.children}
    </Box>
  );
}

BoxLoader.defaultProps = {
  isLoading: false,
};
